import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import * as S from './styles'
import { Icon, Breadcrumb} from 'components'
import { RouteProps } from 'config/navigations'

interface Props {
	routeSegments: RouteProps[]
}

const BreadcrumbRouter : React.FC<Props> = ({ routeSegments }) => {
	return (
		<S.Container>
			<Breadcrumb>
				<NavLink to="/dashboard">
					<S.StyledIcon>
						<Icon className='icon' color='primary'>home</Icon>
					</S.StyledIcon>
				</NavLink>
				{routeSegments.map((route, index) => {
					return index !== routeSegments.length - 1 ? (
						<NavLink key={index} to={route.path}>
							<S.SubName>
								{route.name}
							</S.SubName>
						</NavLink>
					) : (
					<S.SubName key={index}>
						{route.name}
					</S.SubName>
					)
				})}
			</Breadcrumb>
		</S.Container>
	)
}

export default memo(BreadcrumbRouter);
