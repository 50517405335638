import React, { useCallback, useState } from "react";
import axios from "axios";
import * as S from './styles';
import { useFormik } from "formik";
import { Accordion, FormControl, InputAdornment, TextField, IconButton } from "@mui/material";
import { getAddressValidation, IAddressProps, initAddressProps } from "./validations";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { AccordionDetails, AccordionSummary, Grid } from 'components';
import { H5, SubTitlePage } from "styles/typography";
import { AiOutlineSearch } from "react-icons/ai";
import useNotify from "hooks/use-notify";
 
const Address: React.FC = () => {
	const [newAddress, setAddress] = useState(initAddressProps);
	const [isLoading, setIsLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const notify = useNotify();

	const formik = useFormik({
    initialValues: newAddress,
		enableReinitialize: true, 
    validationSchema: getAddressValidation,
    onSubmit: async (fields)  => { }
  })

	const handleGetAddress =  async (cep: string) => {
		try {
			if(!isValidCep(cep)) return;
			setIsLoading(true);
	
			let { data: address } = await axios.get<IAddressProps>(`https://viacep.com.br/ws/${cep}/json/`);
			setAddress(address);
			setIsValid(true);
		} catch (error) {
			notify.error(`Nenhum endereço foi retornado para o cep informado`)
		}
		finally{ setIsLoading(false); }
	}

	const isValidCep = (cep: string) => {
		if(cep.length < 9) return false;

		const pattern = '[0-9]{5}-[0-9]{3}';
		return cep.match(pattern);
	}

	const handleChangeCep = (event) => {
		event.preventDefault()
		var eventClone = {...event};
		var { target: { value } } = eventClone;

		let cep = value.toString().replace(/\D/g, '');
		if(value.toString().length === 6)
			eventClone.target.value = `${cep.substring(0, 5)}-${cep.substring(5)}`;
		
		if(value.toString().length < 10)
			handleChange(eventClone);
	}

	const handleChange = (event) => {
		var { target: { name, value } } = event;
		setAddress(oldValue => ({...oldValue, [name]: value}));
		formik.handleChange(event);
	}
	
	return ( 
		<S.Container>
			<Accordion expanded={isExpanded} sx={{mt: 2}} className={`address-container ${isValid ? 'valid' : ''}`}>
				<AccordionSummary
					expandIcon={<GridExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					onClick={() => setIsExpanded(!isExpanded)}
				>
					<H5>📍 Endereço</H5>
				</AccordionSummary>
				<AccordionDetails>
				<SubTitlePage className="subtitle">Busca realizada via api da viacep</SubTitlePage>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<FormControl fullWidth>
								<TextField
									label='CEP'
									name='cep'
									value={newAddress.cep}
									variant='filled'
									id="outlined-adornment-search"
									type='text'
									placeholder='Informe o cep do endereço'
									onChange={handleChangeCep}
									error={Boolean(formik.touched.cep && formik.errors.cep)}
									helperText={formik.touched.cep && formik.errors.cep}
									disabled={isLoading}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton disabled={!isValidCep(newAddress.cep)} onClick={async () => await handleGetAddress(newAddress.cep)} aria-label="Icone de lupa para pesquisar" edge="end" >
													<AiOutlineSearch  />
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.logradouro && formik.errors.logradouro)}
									label='Endereço'
									placeholder='Informe o endereço'
									helperText={formik.touched.logradouro && formik.errors.logradouro}
									onBlur={formik.handleBlur}
									value={newAddress.logradouro}
									onChange={handleChange}
									variant='filled'
									disabled={isLoading}
									name='logradouro'
									type='text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.cep && formik.errors.numero)}
									label='Número'
									placeholder='Informe o número do endereço'
									helperText={formik.touched.numero && formik.errors.numero}
									onBlur={formik.handleBlur}
									value={newAddress.numero}
									onChange={handleChange}
									variant='filled'
									name='numero'
									type='text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.bairro && formik.errors.bairro)}
									label='Bairro'
									placeholder='Informe a bairro do endereço'
									helperText={formik.touched.bairro && formik.errors.bairro}
									onBlur={formik.handleBlur}
									value={newAddress.bairro}
									onChange={handleChange}
									variant='filled'
									disabled={isLoading}
									name='bairro'
									type='text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={8} sm={8} md={4} lg={4}>
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.localidade && formik.errors.localidade)}
									label='Cidade'
									placeholder='Informe a cidade do endereço'
									helperText={formik.touched.localidade && formik.errors.localidade}
									onBlur={formik.handleBlur}
									value={newAddress.localidade}
									onChange={handleChange}
									variant='filled'
									disabled={isLoading}
									name='localidade'
									type='text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.uf && formik.errors.uf)}
									label='Estado'
									placeholder='Informe o uf do endereço'
									helperText={formik.touched.uf && formik.errors.uf}
									onBlur={formik.handleBlur}
									value={newAddress.uf}
									onChange={handleChange}
									variant='filled'
									disabled={isLoading}
									name='uf'
									type='text'
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} >
							<FormControl fullWidth>
								<TextField 
									error={Boolean(formik.touched.complemento && formik.errors.complemento)}
									label='Complemento'
									placeholder='Informe a complemento do endereço'
									helperText={formik.touched.complemento && formik.errors.complemento}
									onBlur={formik.handleBlur}
									value={newAddress.complemento}
									onChange={handleChange}
									variant='filled'
									disabled={isLoading}
									name='complemento'
									type='text'
								/>
							</FormControl>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</S.Container>
	);
}
 
export default Address;