import React, { memo } from 'react'
import { Tooltip as TooltipMUI, Zoom } from '@mui/material'

interface TooltipProps {
	children: React.ReactElement
	title: string,
	placement?: "bottom" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top"
}
 
const Tooltip = ({children, title, placement} : TooltipProps )  => {
	return ( 
		<>
			<TooltipMUI 
				arrow
				title={title} 
				enterDelay={100} 
				TransitionComponent={Zoom as React.ComponentType}
				placement={placement ||  "bottom" }
			>
				{children}
			</TooltipMUI>
		</>
	)
}
 
export default memo(Tooltip)
