import styled from 'styled-components'

export const Container = styled.section`
	flex: 1;

	& .custom-list-container {
		-webkit-font-smoothing: antialiased;
		&::-webkit-scrollbar {
			background-color: ${props => props.theme.palette.grey[400]};
			border-radius: 8px;
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			background-color: ${props => props.theme.palette.primary.main};
			min-height: 24px;
			border: 3px solid ${props => props.theme.palette.primary.main};
		}
		&::-webkit-scrollbar-thumb:focus {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-thumb:active {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-thumb:hover {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-corner {
			background-color: ${props => props.theme.palette.primary.light};
		}
	}
`

export const ListSelected = styled.section`
  flex: 1;
	height: 55vh;
	max-height: 55vh;
  border-radius: 18px;
  padding: 18px;
	background-color: ${props => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;

	box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  & > .description{
    font-size: 14px;
    letter-spacing: 0.3px;
  }
  & > .header {
    display: flex;
    align-items: center;
    & > h2 {
      font-size: 18px;
			margin-left: 12px;
    }
  }

	& .MuiListItem-padding {
		display: none!important;
	}

	& > .list-people {
		margin-top: 12px;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-right: 8px;
		overflow-y: auto;

		.badge + .badge {
			margin-left: 8px;
		}
		
		-webkit-font-smoothing: antialiased;
		&::-webkit-scrollbar {
			background-color: ${props => props.theme.palette.grey[400]};
			border-radius: 8px;
			width: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			background-color: ${props => props.theme.palette.primary.main};
			min-height: 24px;
			border: 3px solid ${props => props.theme.palette.primary.main};
		}
		&::-webkit-scrollbar-thumb:focus {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-thumb:active {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-thumb:hover {
			background-color: ${props => props.theme.palette.primary.light};
		}
		&::-webkit-scrollbar-corner {
			background-color: ${props => props.theme.palette.primary.light};
		}

		button { border: none; }
		& > li {
			cursor: pointer;
			& + li { margin-top: 8px; }
			display: flex;
			align-items: center;
			justify-content: space-between;

			border-radius: 4px;
			background-color: ${props => props.theme.palette.grey[200]};
			font-size: 14px;

		}
	}
`