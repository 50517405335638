import React, { memo } from 'react';
import { Button as ButtonMUI, SxProps, Theme } from '@mui/material';
import { IconBaseProps } from 'react-icons';
import { useNavigate } from 'react-router-dom';

export interface ButtonProps {
	to?: string,
  text?: string,
	variant?: 'contained' | 'outlined' | 'text',
	size?: 'small' | 'medium' | 'large',
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  startIcon?: IconBaseProps,
	endIcon?: IconBaseProps,
	fullWidth?: boolean,
	disableElevation?: boolean,
	className?: string,
	onClick?: React.MouseEventHandler<HTMLButtonElement>,
	children?: React.ReactNode,
	sx?: SxProps<Theme>
}

const Button: React.FC<ButtonProps> = (
	{ 
		to,
		text, 
		children,
		...props 
	}) => {
		const navigate = useNavigate();

	return (
		<ButtonMUI 
			{...(to && {onClick: () => navigate(to)})}
			{...props}
		>
			{!!children ? children : text}
		</ButtonMUI>
  )
}

export default memo(Button);
