import React from 'react';
import { Loadable } from 'components';
import { StoreCampaings } from 'contexts';

const Campagins = Loadable(React.lazy(() => import('./index')));
const CampaignCreate = Loadable(React.lazy(() => import('pages/campaigns/components/campaign-create')));

const campaignsRoutes = [
	{
		path: '/campanhas',
		element: 
			<StoreCampaings>
				<Campagins />
			</StoreCampaings>,
	},
	{
		path: '/campanha/criar',
		element: <CampaignCreate />,
	},
]

export default campaignsRoutes;
