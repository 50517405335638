import styled from 'styled-components'

export const Container = styled.section`
	flex:1;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: stretch;
	width: 100%;
	min-height: 20vh;
	height: 100%;
`;