export type RouteProps = {
	name?: string,
	label?: string,
	path?: string,
	icon?: string,
	type?: string,
}

export const navigations : RouteProps[] = [
	{
	name: 'Dashboard',
	path: '/dashboard',
	icon: 'dashboard',
	},
	{
		name: 'Tarefas',
		path: '/tarefas',
		icon: 'boltz',
	},
	{
		name: 'Campanhas',
		path: '/campanhas',
		icon: 'bubble_chart',
	},
	{
		name: 'Usuários',
		path: '/usuarios',
		icon: 'person',
	},
	{
		name: 'Empresas',
		path: '/empresas',
		icon: 'apartment',
	},
	{
		name: 'API',
		path: '/apidoc',
		icon: 'data_object',
	},
]
