import React from 'react';
import useSettings from 'hooks/use-settings';
import GlobalStyles from 'styles/global';
import { ThemeProvider as ThemeSC } from "styled-components";
import { CssBaseline, StyledEngineProvider, ThemeProvider as ThemeMui, useMediaQuery } from '@mui/material';
import { createContext, useContextSelector } from 'use-context-selector';

const ThemeContext = createContext({isMobile: false});

const ThemesProvider : React.FC = ({ children }) => {
	console.log('renderizou ThemeProvider')
	const { activeTheme } = useSettings();
	const isMobile = window.matchMedia(`(max-width: 420px)`).matches;

	return (
		<StyledEngineProvider injectFirst>
			<ThemeMui theme={activeTheme}>
				<ThemeSC theme={activeTheme}>
					<CssBaseline />
					<GlobalStyles />
					<ThemeContext.Provider value={{isMobile}}>
						{children}
					</ThemeContext.Provider>
				</ThemeSC>
			</ThemeMui>
		</StyledEngineProvider>
	)
}

export default ThemesProvider;
