import SettingsContext from 'contexts/settings-context';
import { useContextSelector } from 'use-context-selector';

const useSettings = () => {
	const settings = useContextSelector(SettingsContext, ctx => ctx.settings);
	const updateSettings = useContextSelector(SettingsContext, ctx => ctx.updateSettings);
	const updateSidebarMode = useContextSelector(SettingsContext, ctx => ctx.updateSidebarMode);
	const activeTheme = useContextSelector(SettingsContext, ctx => ctx.settings.themes[ctx.settings.activeTheme]);
	const topBarTheme = useContextSelector(SettingsContext, ctx => ctx.settings.themes[ctx.settings.layoutSettings.topbar.theme])
	const leftSidebar = useContextSelector(SettingsContext, ctx => ctx.settings.layoutSettings.leftSidebar);
	const leftSidebarMode = useContextSelector(SettingsContext, ctx => ctx.settings.layoutSettings.leftSidebar.mode);

	return {
		activeTheme,
		settings,
		updateSettings,
		updateSidebarMode,
		leftSidebar,
		topBarTheme,
		leftSidebarMode
	}
};

export default useSettings;
