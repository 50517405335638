import React, { memo } from 'react';
import * as S from './styles';
import { DataGrid, Fade } from 'components';

interface ListTableProps {
	headers: any[],
	rows: any[],
	checkboxSelection?: boolean,
	disableSelectionOnClick?: boolean
}

/**
 * Não deve ser alterado a visiblidade do DataGrid atravéz da propriedade display:none
 * isso faz com que o container pai do DataGrid tem um heigth = 0 e são gerados erros pro MUI
 */
const ListTable: React.FC<ListTableProps> = ({ 
	headers, 
	rows,
	...rest
 }) => {

	return (
		<Fade timeout={500} in>
			<S.Container className='container-list-table'>
				<DataGrid
					{...rest}
					autoHeight
					columns={headers}
					rows={rows}
					pageSize={6}
					rowsPerPageOptions={[]}
					columnBuffer={12}
					columnThreshold={10} 
					/>
			</S.Container>
		</Fade>
	)
}

export default memo(ListTable);
