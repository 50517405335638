import React, { Suspense } from 'react';
import { LoadingMUI } from 'components';

const LoadableMUI = (Component) => (props) => (
	<Suspense fallback={<LoadingMUI />}>
		<Component {...props} />
	</Suspense>
)

export default LoadableMUI;
