import styled from 'styled-components';

export const Container = styled.section`
	.address-container {
		margin: 0;
		padding-bottom: 36px;
		&.valid {
			border: solid 2px ${props => props.theme.palette.success.main};
			box-shadow: 6px 6px 5px 0px rgba(46,255,157,0.49);
		}
		padding: 12px;
		@media (max-width: 480px){
			padding: 6px;
		}
	}
	h5 {
		font-weight: 500;
	}
	.subtitle {
		margin-bottom: 12px;
	}
`;
