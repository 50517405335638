import React from 'react';
import * as S from './styles';
import { Button } from 'components';

interface ISwitchProps {
	isActived: boolean,
	setActived: (actived: boolean) => void,
	name?: string,
}

const SwitchStatus: React.FC<ISwitchProps> = ({ isActived, setActived }: ISwitchProps) => {
  return (
    <S.Container className="switch-container">
			<Button 
				className={isActived ? 'active' : ''}
				variant="contained"
				size="large" 
				onClick={() => setActived(true)}
				text='Ativo'
			/>
			<Button 
				className={!isActived ? 'active' : ''}
				variant="contained"
				size="large" 
				onClick={() => setActived(false)}
				text='Inativo'
			/>
    </S.Container>
  )
}

export default React.memo(SwitchStatus);
