import { themeColors } from 'components/theme-provider/styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 0 6px;
  transition: margin 2s;
  background-color: ${themeColors.gamefication.palette.grey[300]};
	max-height: 48px;

	& > button {
		flex: 1;
		transition: margin 0.1s;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 6px;
    height: 48px;
    min-width: 80px;
		box-shadow: none;
		min-width: 120px;

		&:hover{ box-shadow: none; }
		&:active{ box-shadow: none; }
    & > svg { margin-right: 6px; }
	}

	& > button:nth-child(1){
		background-color: ${themeColors.gamefication.palette.grey[300]};
		color: #000;
		text-transform: uppercase;
		margin-top: 0;
		&.active {
			margin-top: 12px; 
			font-weight: 600;
			background-color: ${props => props.theme.palette.success.main};
			color: #000;
		}
	}

	& > button:nth-child(2){
		background-color: ${themeColors.gamefication.palette.grey[300]};
		color: #000;
		text-transform: uppercase;
		margin-top: 0;
		&.active {
			margin-top: 12px; 
			font-weight: 600;
			background-color: ${props => props.theme.palette.secondary.main};
			color: ${props => props.theme.palette.common.white};
		}
	}

`
