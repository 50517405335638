import React from 'react';
import { navigations } from 'config/navigations';
import { ThemeProvider } from '@mui/material';
import * as S from './styles';
import { Hidden, VerticalNav, useTheme } from 'components';
import useSettings from 'hooks/use-settings';

const Sidenav : React.FC = ({ children }) => {
	const theme = useTheme();
	const { settings, leftSidebar, updateSidebarMode } = useSettings();
	const sidenavTheme = settings.themes[leftSidebar.theme] || theme

	return (
		<ThemeProvider theme={sidenavTheme}>
			<S.Container>
				<S.Header>
					{children}
					<VerticalNav items={navigations} />
				</S.Header>

				<Hidden lgUp>
					<S.ContainerMobile onClick={() => updateSidebarMode('close')} />
				</Hidden>
			</S.Container>
		</ThemeProvider>
	)
}

export default React.memo(Sidenav);
