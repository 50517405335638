import React from 'react';
import { Button, Grow, useTheme } from 'components';
import { SnackbarProvider, } from 'notistack';
import { NotifyProvider } from 'contexts';

const notistackRef = React.createRef<SnackbarProvider>();

export const Notistack: React.FC = (props) => {
	console.log('renderizou Notistack')
	const theme = useTheme();
	const styles = {
		success: theme.palette.success.main,
		error: theme.palette.error.main,
		warning: theme.palette.warning.main,
		info: theme.palette.info.main,
	}

	const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
	}

	return (
		<SnackbarProvider
			ref={notistackRef}
			hideIconVariant
			preventDuplicate
			classes={{
				variantSuccess: styles.success,
				variantError: styles.error,
				variantWarning: styles.warning,
				variantInfo: styles.info
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			action={(key) => (
        <Button onClick={onClickDismiss(key)}>
					Fechar
        </Button>
    	)}
			TransitionComponent={Grow as React.ComponentType}
		>
			<NotifyProvider>
				{props.children}
			</NotifyProvider>
		</SnackbarProvider>
	)
}
