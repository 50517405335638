import api from 'services/api';
import { ITokenProps } from 'interfaces';

const PATH = 'tarefa';

export const login = async (username: string, password: string) => {
	try {
		let auth = await api.post<ITokenProps>(`autenticacao`, { username, password });
		let { access_token : token } = auth.data;

		return token;
	} catch (error) {
		console.log(error);
		throw error;
	}
}
