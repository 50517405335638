import api from 'services/api';
import { IUserProps } from 'interfaces';
import { IUserCreateValues } from 'pages/users/components/user-create/validation';

const PATH = 'usuario';

export const createUser = async (user: IUserCreateValues) => {
	try {
		const response = await api.post(`${PATH}`, user);
		return response
	} catch (error) {
		return error.response
	}
}

export const updateUser = async (user: IUserCreateValues) => {
	try {
		const response = await api.patch(`${PATH}/${user.id}`, user);
		return response
	} catch (error) {
		return error.response
	}
}

export const getUsers = async () => {
	try {
		const { data } = await api.get<IUserProps[]>(`${PATH}s`);
		return data;
	} catch (error) {
		return error.response.data;
	}
}

export const getUser = async (userId: string) => {
	try {
		const { data } = await api.get<IUserProps>(`${PATH}/${userId}`);
		return data;
	} catch (error) {
		return error.response.data;
	}
}