import React from 'react'
import { motion, Variants } from 'framer-motion';

interface IVariantsProps extends Variants {
	hidden: {},
	visible: {},
	exit?: {}
}

interface IAnimatedProps {
	variants: IVariantsProps,
	className?: string,
	children: React.ReactNode
}
 
const Animated: React.FC<IAnimatedProps> = ({
	variants, 
	className, 
	children
}) => {
	return ( 
		<motion.section 
			variants={variants}
			initial='hidden'
			animate='visible'
			{...(variants.exit && {exit: variants.exit})}
			{...(className && {className})}
		>
			{children}
		</motion.section>
	 );
}
 
export default React.memo(Animated);
