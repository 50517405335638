import { themes } from 'components/theme-provider/factory';
import { Theme } from '@mui/system';
import { ThemeColorsNames } from 'components/theme-provider/styles/colors';

type LayoutSettingsProps = {
	activeLayout: 'layout1' | 'layout1',
	activeTheme: ThemeColorsNames,
	perfectScrollbar: boolean,
	themes: Theme[],
	layoutSettings : {
		leftSidebar: {
			show: boolean,
			mode: 'full' | 'close' | 'compact' | 'mobile',
			theme: ThemeColorsNames,
			bgImgURL: '/assets/sidebar-bg-light.jpg',
		},
		topbar: {
			show: true,
			fixed: true,
			theme: ThemeColorsNames, 
		}
	}, 

	secondarySidebar: {
		show: boolean,
		open: boolean,
		theme: ThemeColorsNames,
	},

	footer: {
		show: boolean,
		fixed: boolean,
		theme: ThemeColorsNames,
	},
}

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html
export const LayoutSettings : LayoutSettingsProps = {
	activeLayout: 'layout1', // layout1, layout2
	activeTheme: 'gamefication', // View all valid theme colors inside MatxTheme/themeColors.js
	perfectScrollbar: false,
	themes: themes as Theme[],
	layoutSettings : {
		leftSidebar: {
			show: true,
			mode: 'full', // full, close, compact, mobile,
			theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
			bgImgURL: '/assets/sidebar-bg-light.jpg',
		},
		topbar: {
			show: true,
			fixed: true,
			theme: 'whiteBlue', // View all valid theme colors inside MatxTheme/themeColors.js
		}
	}, // open Layout1/Layout1Settings.js

	secondarySidebar: {
		show: true,
		open: false,
		theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
	},
	// Footer options
	footer: {
		show: true,
		fixed: false,
		theme: 'whiteBlue', // View all valid theme colors inside MatxTheme/themeColors.js
	},
}

