import React from 'react';
import { Loadable } from 'components';
import { RouteObject } from 'react-router-dom';

const ApiDoc = Loadable(React.lazy(() => import('./index')));

const apiDocRoutes : RouteObject[] = [
	{
		path: '/apidoc',
		element: <ApiDoc />
	}
]

export default apiDocRoutes;
