import React from 'react';
import { Container } from './styles';
import { 
	Tabs, 
	Tab, 
	Select, 
	MenuItem, 
	FormControl, 
	InputLabel, 
 } from '@mui/material';

export interface IStatusFilterProps {
  items: StatusFilterItemProps[],
	status: string,
	handleFilterStatus: (newStatus: string) => void,
}

export interface StatusFilterItemProps {
  text: string,
  status: string,
  active?: boolean,
}

const StatusFilter: React.FC<IStatusFilterProps> = ({ items, handleFilterStatus, status }) => {
	const isMobile = window.matchMedia(`(max-width: 420px)`).matches;

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
		handleFilterStatus(newValue);
  }

	const makeListStatusTabs = () => {
		return (
			<Container className='status-filter-container'>
				<Tabs value={status} onChange={handleChangeTabs} aria-label="filtro por status">
					{ 
						items.map((item, i) => 
							<Tab key={i} value={item.status} label={item.text} className='status-filter-item' />
						) 
					}
				</Tabs>
			</Container>
		)
	}

	const handleChangeMobile = (event) => {
		handleFilterStatus(event.target.value)
  };

	const makeListStatusMobile = () => {
		return (
			<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={status}
					label="Status"
					onChange={handleChangeMobile}
					fullWidth
				>
					{ 
						items.map((item, i) => 
							<MenuItem key={i} value={item.status.toString()} className='status-filter-item'>{item.text}</MenuItem>
						) 
					}
				</Select>
			</FormControl>
		)
	}

	return isMobile ? makeListStatusMobile() : makeListStatusTabs();
}

export default StatusFilter;
