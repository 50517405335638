import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AllPages } from 'routes/routes';
import { Notistack } from 'components';
import { ThemeProvider } from 'components';
import { AuthProvider, SettingsProvider } from 'contexts';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'services/react-query';

const App: React.FC = () => {
	const all_routes = useRoutes(AllPages());

	return (				
		<QueryClientProvider client={queryClient}>
			<SettingsProvider>
				<ThemeProvider>
					<Notistack>
						<AuthProvider>
							{all_routes}
						</AuthProvider>
					</Notistack>
				</ThemeProvider>
			</SettingsProvider>
		</QueryClientProvider>
	)
}

export default React.memo(App);
