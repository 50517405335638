import React, { memo } from "react";
import { Divider as DividerMUI, DividerProps } from "@mui/material";

interface IDividerProps extends DividerProps { }
 
const Divider: React.FC<IDividerProps> = () => {
	return <DividerMUI textAlign="left" sx={{ my: 1, borderColor: '#cccccc3b' }} />;
}
 
export default memo(Divider);
