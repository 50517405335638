import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Grow,
} from '@mui/material';

interface IdentityCardProps {
	isOpen: boolean,
	setClose: () => void,
	onClose?: () => void,
	children: React.ReactNode
}
 
const IdentityCard: React.FC<IdentityCardProps> = ({
	isOpen,
	onClose,
	setClose,
	children
}) => {
	return ( 
		<Dialog
			open={isOpen}
			TransitionComponent={Grow as React.ComponentType}
			keepMounted
			onClose={onClose}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>Identificação do usuário</DialogTitle>
			<DialogContent sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}} >
				<DialogContentText id="alert-dialog-slide-description" sx={{marginBottom: '1rem'}}>
					{children}
				</DialogContentText>
				<img width={100} src="/assets/illustrations/mobile-face.svg" alt="Imagem ilustativa para scaner qrcode de identificação" />
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setClose()}>Fechar</Button>
			</DialogActions>
		</Dialog>
	 );
}
 
export default React.memo(IdentityCard);
