import { IUserProps } from "./users"

export interface ICampaignProps {
	id: string
  title: string
  description: string
  status: string,
  statusCampanha: string,
  amounts?: {
		tasks: number
		persons: number
		points: number
	}
  topRanking?: (IUserProps & { position:number })[]
}

export enum StatusCampaignEnum {
	Todas = 'T',
	Ativa = 'A',
	Inativa = 'I',
	Suspensa = 'S',
}

export enum PeriodCampaignEnum {
	Permanente = 'M',
	Periodica = 'O'
}

export enum GroupUserCampaignEnum {
	Todos = 'T',
	Selecionados = 'S',
	Lideres = 'L'
}

export enum GroupTaskCampaignEnum {
	Todas = 'T',
	Selecionadas = 'S'
}