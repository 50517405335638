import React, { memo } from 'react'
import { Breadcrumbs as BreadcrumbsMUI, Icon } from '@mui/material'
import { Container } from './styles'

interface BreadcrumbProps { }
 
const Breadcrumb: React.FC<BreadcrumbProps> = ({children}) => {
	return ( 
		<Container className='breadcrumb-container'>
			<BreadcrumbsMUI
				className='breadcrumb'
				separator={<Icon>navigate_next</Icon>}
			>
				{children || null}
			</BreadcrumbsMUI> 
		</Container>
	)
}
 
export default memo(Breadcrumb)
