import { IVariantsProps } from "interfaces/interfaces";

export const leftToRightAnimation = (delay?) : IVariantsProps => (
	{
		hidden: { x: '-200px', opacity: 0 },
		visible: {
			opacity: 1,
			x: 0,
			transition: {
				delay: (delay ? delay : 0.3)
			}
		}
	}
)

export const fadeSlideDownToUp = (delay?) : IVariantsProps => (
 	{
		hidden: { y: 60, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
			transition:{
				duration: (delay ? delay : 0.3)
			}
		}
	}
)

export const fadeSlideUpToDown = (delay?) : IVariantsProps => (
	{
		hidden: { y: '-60', opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
			transition:{
				duration: (delay ? delay : 0.3)
			}
		}
	}
)

export const springRightToLeftAnimation = (delay?, slow?) : IVariantsProps => (
	{
		hidden: { x: 180, opacity: 0 },
		visible: {
			x: 1,
			opacity: (slow ? [0.3, 1] : 1),
			transition: { 
				delay: (delay ? delay : 0.55),
				type: "spring", 
				damping: 10,
				mass: 0.55,
				stiffness: 200
			}
		}
	}
)

export const zoomInAnimation = (delay?) : IVariantsProps => (
	{
		hidden: { scale: 0, opacity: 0 },
		visible: {
			scale: 1,
			opacity: 1,
			transition: {
				delay: (delay ? delay : 0.3)
			}
		}
	}
)

export const zoomOutAnimation = (delay?) => (
	{ 
		opacity: 0, 
		scale: 0,
		transition: {
			duration: (delay ? delay : 0.3),
		}
	}
)