import api from 'services/api';
import { ICampaignProps } from 'interfaces';
import { ICampaignCreateConfig } from 'pages/campaigns/components/campaign-create-config/validations';

const PATH = 'campanha';

export const getCampaigns = async () => {
	try {
		let { data: campaignsList } = await api.get<ICampaignProps[]>('/tarefas');
		// let { data: campaignsList } = await api.get<ICampaignProps[]>(`${PATH}s`);

		return campaignsList;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const getCampaignById = async (id: string) => {
	try {
		const { data: campaign } = await api.get<ICampaignProps>(`/campanha/${id}`);

		return campaign;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const handleCreateCampaign = async (newCampaign : ICampaignCreateConfig) => {
	try {
		newCampaign.periodo = newCampaign.periodo.toString();

		await api.post(`${PATH}`, newCampaign);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const handleUpdateCampaign = async (newCampaign : ICampaignCreateConfig) => {
	try {
		newCampaign.periodo = newCampaign.periodo.toString();

		await api.put(`${PATH}`, newCampaign);
	} catch (error) {
		console.log(error);
		throw error;
	}
}
