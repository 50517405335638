import React from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { IAnimatedProps } from "interfaces/interfaces";

const AnimatedList: React.FC<IAnimatedProps> = ({
	variants,
	children
}) => {
	return (
		<motion.ul
			className='animated-list-container'
			variants={variants}
			style={{display: 'flex', flexWrap: 'wrap'}}
			initial="hidden"
			animate="visible"
		>
			<AnimatePresence exitBeforeEnter>
				{children}
			</AnimatePresence>
		</motion.ul>
	)
}

export default AnimatedList;
