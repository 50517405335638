import React, { useCallback, useState } from 'react';
import * as S from './styles';
import useAuth from 'hooks/use-auth';
import { zoomInAnimation } from 'styles/animation';
import { mockImgAvatarSkin } from 'utils';
import { Animated, Avatar } from 'components';
import { Span } from 'styles/typography';
import { Menu } from '@mui/material';
import { IUserMenuItemProps } from '../user-menu-item';
import UserMenuItem from '../user-menu-item';
import QrCodePerfilIcon from '@mui/icons-material/QrCode'

interface IUserMenuProps {
	horizontalPosition?: number | "right" | "left" | "center",
}

// component: <QrCodePerfilIcon />,
const itemsMenu : IUserMenuItemProps[] = [
	{
		label: 'Perfil',
		to: '/perfil',
		icon: 'person',
	},
	{
		label: 'Logout',
		onClick: null,
		icon: 'power_settings_new',
	}
]

const LayoutUserMenu : React.FC<IUserMenuProps> = ({
	horizontalPosition = 'left'
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { user } = useAuth();

	const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), []);
	const handleClose = useCallback(() =>  setAnchorEl(null), []);

	const makeUserMenuButton = () => 
		<Animated variants={zoomInAnimation(0.45)} >
			<S.UserMenuButton className='user-menu-button'>
				<Span>
					<strong>👑 {user.nome}</strong>
				</Span>
				<Avatar
					src={mockImgAvatarSkin(Math.floor((Math.random() * (47 - 1) + 1)))}
					sx={{ cursor: 'pointer' }}
				/>
			</S.UserMenuButton>
		</Animated>

	return (
		<S.MenuContainer>
			<S.UserMenuContainer className='container-user-menu-button' onClick={handleClick}>
				{makeUserMenuButton()}
			</S.UserMenuContainer>
			<Menu
				elevation={8}
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: horizontalPosition,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: horizontalPosition,
				}}
			>
				{
					itemsMenu.map((item, index) => (
						<UserMenuItem 
							key={index} 
							onClick={handleClose}
							label={item.label}
							icon={item.icon}
							to={item.to}
						/>
					))
				}
			</Menu>
		</S.MenuContainer>
	)
}

export default React.memo(LayoutUserMenu);
