import styled, { css } from 'styled-components'

type Props = {
	ownerState: { 
		completed?: boolean, 
		active?: boolean 
	}
}

export const Container = styled.section`
	margin-bottom: 1rem;

	.step {
		cursor: pointer;
	}
`

export const ColorlibStepIconRoot = styled.section<Props>`
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

	${props => props.ownerState.active && css`
		/* TRANSITION */
		-webkit-transition: background-image 0.2s ease-in-out;
		transition: background-image 0.2s ease-in-out;
		background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.25);
	`}

	${props => props.ownerState.completed && css`
	/* TRANSITION */
		-webkit-transition: background-image 0.2s ease-in-out;
		transition: background-image 0.2s ease-in-out;
		background-image: linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);
	`}
`