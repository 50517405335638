import React, { memo } from 'react'
import * as S from './styles'
import { styled } from '@mui/material/styles'

import { Stack, Step, StepIconProps, StepLabel, Stepper } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import AssignmentIcon from '@mui/icons-material/Assignment'

interface AssistantProps {
	width?: string	
	maxWidth?: string
	fullWidth?: boolean
	steps: string[],
	setStep?: React.Dispatch<React.SetStateAction<number>>,
	stepIndex?: number
}

const Assistant: React.FC<AssistantProps> = (props) =>{
	const width = !!props.fullWidth ? '100%' : props.width ?? '100%'
	const maxWidth = props.maxWidth ?? props.width ?? '600px'
	const steps = props.steps

  return (
		<S.Container>
			<Stack 
				sx={{ 
					width: width, 
					maxWidth: maxWidth,
					margin: '0 auto'
					}} 
				spacing={4}
				className='assistant-container'
				>
				<Stepper 
					alternativeLabel 
					activeStep={props.stepIndex ?? 0} 
					connector={<ColorlibConnector />}>
					{steps.map((label, key) => (
						<Step className='step' key={label} onClick={() => props.setStep(key)}>
							<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
						</Step>	
					))}
				</Stepper>
			</Stack>
		</S.Container>
  )
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
			transition: 'background-image 0.4s ease-in-out',
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
			transition: 'background-image 0.4s ease-in-out',
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <AssignmentIcon />,
  };

  return (
    <S.ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </S.ColorlibStepIconRoot>
  )
}

export default memo(Assistant)
