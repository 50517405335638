import React, { lazy } from 'react';
import { Loadable } from 'components';
import { RouteObject } from 'react-router-dom';
import { StoreTasks } from 'contexts';

const Tasks = Loadable(lazy(() => import('./index')));
const TaskCreate = Loadable(lazy(() => import('pages/tasks/components/task-create')));
const TaskView = Loadable(lazy(() => import('pages/tasks/components/task-view')));

const tarefasRoutes : RouteObject[] = [
	{
		path: '/tarefas',
		element: 
			<StoreTasks>
				<Tasks />
			</StoreTasks>
	},
	{
		path: '/tarefa/:id',
		element: <TaskView />,
	},
	{
		path: '/tarefa/criar',
		element: 
			<StoreTasks>
				<TaskCreate />
			</StoreTasks>,
	},
	{
		path: '/tarefa/:id/editar',
		element: <TaskCreate />,
	},
]

export default tarefasRoutes;
