import React, { memo } from 'react'
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { AppsSharp, ViewList } from '@mui/icons-material'

type SwitchProps = {
	type: string
	setType: (type: string) => void
}

const SwitchTypeShow: React.FC<SwitchProps> = ({ type, setType }) => {
	const handleToggle = (_, newType: string) => {
		if (newType !== null) {
			setType(newType)
		}
	}
  return (
			<Stack className="switch-container" direction="row" spacing={4}>
				<ToggleButtonGroup
					value={type}
					exclusive
					size='small'
					onChange={handleToggle}
					color={'primary'}
				>
					<ToggleButton value='panel' sx={{ pl: 2, pr: 2 }}>
						Painel <AppsSharp fontSize='small' sx={{ ml: 1 }} />
					</ToggleButton>
					<ToggleButton value='list' sx={{ pl: 2, pr: 2 }}>
						Tabela <ViewList fontSize='small' sx={{ ml: 1 }} />
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>
  )
}

export default memo(SwitchTypeShow)
