import api from 'services/api';
import { ITaskCreateValues } from 'pages/tasks/components/task-create/validations';
import { ITaskProps } from 'interfaces';

const PATH = 'tarefa';

export const getTasks = async () => {
	try {
		let { data: listTasks } = await api.get<ITaskProps[]>(`${PATH}s/`);

		return listTasks;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const getTask = async (id: string) => {
	try {
		let { data: task } = await api.get<ITaskProps>(`${PATH}/${id}`);

		return task;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const createTask = async (task: ITaskCreateValues) => {
	try {
		task.status = task.status.toString();
		task.pontos = +task.pontos;

		await api.post(`${PATH}`, task);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const updateTask = async (task: ITaskCreateValues) => {
	try {
		task.pontos = +task.pontos; 

		await api.patch(`${PATH}/${task.id}`, task);
	} catch (error) {
		console.log(error);
		throw error;
	}
}

export const removeTask = async (taskId: string) => {
	try {
		const response = await api.delete(`${PATH}/${taskId}`);
		return response
	} catch (error) {
		console.log(error);
		throw error;
	}
}