import styled from 'styled-components'

export const StyledLoading = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& img {
		width: auto;
		height: 120px;
	}

	& .circleProgress {
		margin-top: 2rem;
	}
`
