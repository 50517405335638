import React, { memo } from 'react'
import * as S from './styles'
import { Icon as IconMUI, IconProps as IconPropsMUI } from '@mui/material'
export interface IIconProps extends Omit<IconPropsMUI, 'fontSize'> {
	fontSize?: string | number | 'small' | 'inherit' | 'large' | 'medium'
}
 
const Icon: React.FC<IIconProps> = ({
	children, 
	fontSize,
	...props
}) => {
	return (
		<S.Container className='icon-container' fontSize={fontSize}>
			<IconMUI {...props}>
				{children || null}
			</IconMUI>
		</S.Container>
	)
}
 
export default memo(Icon);
