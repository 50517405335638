import { AuthContext } from 'contexts';
import { useContextSelector } from 'use-context-selector';

const useAuth = () => {
	const login = useContextSelector(AuthContext, ctx => ctx.login)
	const logout = useContextSelector(AuthContext, ctx => ctx.logout)
	const user = useContextSelector(AuthContext, ctx => ctx.user)
	const isAuthenticated = useContextSelector(AuthContext, ctx => ctx.isAuthenticated)
	const isInitialised = useContextSelector(AuthContext, ctx => ctx.isInitialised)

	return {
		login,
		logout,
		user,
		isAuthenticated,
		isInitialised,
	}
}

export default useAuth;
