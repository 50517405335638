import styled from 'styled-components';

const weightTitles = 600;

export const TitlePage = styled.h1`
	font-size: 2rem;
	font-weight: ${weightTitles};
	text-transform: capitalize;
`

export const SubTitlePage = styled.p`
	font-size: 0.875rem;
	color: ${props => props.theme.palette.text.secondary};
`;

export const H1 = styled.h1`
	font-size: 2rem;
	font-weight: ${weightTitles};
`;

export const H2 = styled.h2`
	font-size: 1.8rem;
	font-weight: ${weightTitles};
`;

export const H3 = styled.h3`
	font-size: 1.6rem;
	font-weight: ${weightTitles};
`;

export const H4 = styled.h4`
	font-size: 1.4rem;
	font-weight: ${weightTitles};
`;

export const H5 = styled.h5`
	font-size: 1.2rem;
	font-weight: ${weightTitles};
`;

export const H6 = styled.h6`
	font-size: 1.1rem;
	font-weight: ${weightTitles};
`;

export const Paragraph = styled.p`

`;

export const Span = styled.span`
	
`;

export const Small = styled.small`
	
`;