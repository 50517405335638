import { 
	PageState, 
	ICampaignProps, 
	ICompanyProps, 
	ITaskProps, 
	IUserProps, 
	StatusCampaignEnum, 
	StatusCompanyEnum, 
	StatusTaskEnum, 
	StatusUserEnum 
} from "interfaces";

export const initialCampaingsState : PageState<ICampaignProps> = {
	search: '',
	setSearch: (newValue: string) => {},
	status: StatusCampaignEnum.Todas,
	setStatus: (newStatus: StatusCampaignEnum) => {},
	typeShow: 'panel',
	setTypeShow: (newType: 'panel' | 'list') => {},
	list: [],
	setList: (list: ICampaignProps[]) => {},
	isLoading: true,
	isError: false,
	amounts: null,
}

export const initialCompanyesState : PageState<ICompanyProps> = {
	search: '',
	setSearch: (newValue: string) => {},
	status: StatusCompanyEnum.Todas,
	setStatus: (newStatus: StatusCompanyEnum) => {},
	typeShow: 'panel',
	setTypeShow: (newType: 'panel' | 'list') => {},
	list: [],
	setList: (list: ICompanyProps[]) => {},
	isLoading: true,
	isError: false,
	amounts: null,
}

export const initialTasksState : PageState<ITaskProps> = {
	search: '',
	setSearch: (newValue: string) => {},
	status: StatusTaskEnum.Todas,
	setStatus: (newStatus: StatusTaskEnum) => {},
	typeShow: 'panel',
	setTypeShow: (newType: 'panel' | 'list') => {},
	list: [],
	remove: (id: string) => Promise.resolve(),
	setList: (list: ITaskProps[]) => {},
	isLoading: true,
	isError: false,
	amounts: null
}

export const initialUsersState : PageState<IUserProps> = {
	search: '',
	setSearch: (newValue: string) => {},
	status: StatusUserEnum.Todos,
	setStatus: (newStatus: StatusUserEnum) => {},
	typeShow: 'panel',
	setTypeShow: (newType: 'panel' | 'list') => {},
	list: [],
	setList: (list: IUserProps[]) => {},
	isLoading: true,
	isError: false,
	amounts: null
}