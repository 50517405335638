import React from 'react';
import { styled } from '@mui/system';
import { Grid, LinearProgress, Typography, useTheme } from '@mui/material';

const CustomLinearProgress = styled(LinearProgress)(() => ({
    borderRadius: 2,
    background: 'rgba(0, 0, 0, 0.1)',
}))

const MatxProgressBar = ({
    value = 75,
    color = 'primary',
    text = '',
    spacing = 2,
    coloredText = false,
}) => {
	const theme = useTheme()
	const secondary = theme.palette.text.secondary

	return (
		<Grid
			container
			spacing={spacing}
			alignItems="center"
		>
			<Grid item xs={text ? 8 : 12}>
				<CustomLinearProgress
						color={color}
						value={value}
						variant="determinate"
				/>
			</Grid>
			{text && (
				<Grid item xs={text ? 4 : false}>
					<Typography color={color}>
						<small sx={{ color: coloredText ? '' : secondary }}>{text}</small>
					</Typography>
				</Grid>
			)}
		</Grid>
	)
}

export default MatxProgressBar
