import { Navigate } from 'react-router-dom';
import sessionRoutes from 'pages/sessions/routes';
import NotFound from 'pages/sessions/not-found';
import AuthGuard from 'auth/auth-guard';
import MatxLayout from 'components/layout/MatxLayout';
import dashboardRoutes from 'pages/dashboard/routes';
import campaignsRoutes from 'pages/campaigns/routes';
import tarefasRoutes from 'pages/tasks/routes';
import usersRoutes from 'pages/users/routes';
import companyeRoutes from 'pages/companyes/routes';
import apiDocRoutes from 'pages/apidoc/routes';

export const AllPages = () => {
	const all_routes = [
		{
			element: 
				<AuthGuard>
					<MatxLayout />
				</AuthGuard>,
			children: [
				...dashboardRoutes, 
				...campaignsRoutes, 
				...tarefasRoutes, 
				...usersRoutes,
				...companyeRoutes,
				...apiDocRoutes
			],
		},
		...sessionRoutes,
		{
			path: '/',
			element: <Navigate to="/login" />,
		},
		{
			path: '/cadastro',
			element: <Navigate to="/cadastro" />,
		},
		{
			path: '*',
			element: <NotFound />,
		},
	];

	return all_routes;
}