import React, { lazy } from 'react';
import { Loadable } from 'components';
import { RouteObject } from 'react-router-dom';

const NotFound = Loadable(lazy(() => import("./not-found")))
const ForgotPassword = Loadable(lazy(() => import("./forgot-password")))
const Login = Loadable(lazy(() => import("./login")))
const SignUp = Loadable(lazy(() => import("./signup")))

const sessionRoutes : RouteObject[] = [
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/esqueceu-senha',
		element: <ForgotPassword />,
	},
	{
		path: '/cadastro',
		element: <SignUp />,
	},
	{
		path: '/404',
		element: <NotFound />,
	},
]

export default sessionRoutes;
