import React from 'react';
import { Loadable } from 'components';
import { StoreCompanyes } from 'contexts';
import { RouteObject } from 'react-router-dom';

const Company = Loadable(React.lazy(() => import('./index')));
const CompanyCreate = Loadable(React.lazy(() => import('pages/companyes/components/company-create')));
const CompanyUpdate = Loadable(React.lazy(() => import('pages/companyes/components/company-create')));

const companyesRoutes : RouteObject[] = [
	{
		path: '/empresas',
		element: 
			<StoreCompanyes>
				<Company />,
			</StoreCompanyes>
	},
	{
		path: '/empresa/criar',
		element: <CompanyCreate />,
	},
	{
		path: '/empresa/:id/editar',
		element: <CompanyUpdate />,
	},
]

export default companyesRoutes;
