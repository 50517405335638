import React from 'react';
import { Loadable } from 'components';

const Dashboard = Loadable(React.lazy(() => import('./index')))

const dashboardRoutes = [
	{
		path: '/dashboard',
		element: <Dashboard />,
	},
]

export default dashboardRoutes;
