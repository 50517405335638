import React from 'react';
import useAuth from 'hooks/use-auth';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
	const { isAuthenticated } = useAuth();

	return (
		isAuthenticated 
			? <>{children}</> 
			: <Navigate to="/login" />)
}

export default AuthGuard;
