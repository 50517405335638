import styled from 'styled-components';
import { themeColors } from 'components/theme-provider/styles/colors';

export const Container = styled.section`
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;

	.body {
		display: flex;
    flex-direction: column;
		justify-content: center;
    align-items: center;
	}

	img {
		margin-top:30px;
		width: 380px;
		max-width: 150px;
	}

	p {
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: 1px;
		margin-top: 24px;
		color: ${themeColors.gamefication.palette.grey[500]};
		text-align: center;
	}

	@media screen and (max-width: 400px) {
		p {
			font-size: 1.2rem;
			margin-top: 24px;
			color: ${themeColors.gamefication.palette.grey[500]}
		}
		img {
			max-height: 30vh;
		}
	}
`;
