import React from 'react';
import { Icon } from 'components';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, ListItemText, Paper } from '@mui/material';

export interface IUserMenuItemProps { 
	label: string,
	onClick?: React.MouseEventHandler<HTMLLIElement>,
	to?: string,
	icon?: string,
}

const UserMenuItem: React.FC<IUserMenuItemProps> = ({
	label,
	onClick,
	to,
	icon
}) => {
	const navigate = useNavigate();

	return (
		<MenuItem
			sx={{ width: 240 }}
			className='menu-item' 
			onClick={(to ? () => navigate(to) : onClick)}
		>
			{ icon && (
				<ListItemIcon>
					<Icon>{icon}</Icon>
				</ListItemIcon>
			)}
			<ListItemText>{label}</ListItemText>
		</MenuItem>
	);
}
 
export default React.memo(UserMenuItem);
