import React, { useCallback, useEffect, useReducer } from 'react';
import { createContext } from 'use-context-selector';
import jwt_decode from 'jwt-decode';
import { reducer } from './reducer';
import { IUserProps } from 'interfaces';
import { KEY_TOKEN_AUTH, setSession } from 'utils/localStorage';
import { Loading } from 'components';
import { setHeaderBearer, login as loginApi, getUser } from 'services/api';

export interface IAuthProps {
	method: string,
	isAuthenticated: boolean,
	isInitialised: boolean,
	login: (email: string, password: string) => Promise<void>,
	logout: () => void,
	user: IUserProps,
}

const initialState : IAuthProps = {
	method: 'JWT',
	isAuthenticated: false,
	isInitialised: false,
	login: (email: string, password: string) => Promise.resolve(),
	logout: () => Promise.resolve(),
	user: null,
}

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
	console.log('renderizou AuthProvider')
	const [state, dispatch] = useReducer(reducer, initialState);

	const setToken = useCallback((accessToken: string) => {
		setSession(KEY_TOKEN_AUTH, accessToken);
		setHeaderBearer(accessToken);
	},[])

	const isValidToken = useCallback((accessToken: string) => {
		if (!accessToken) return false;
	
		const decodedToken = jwt_decode(accessToken) as any;
		const currentTime = Date.now() / 1000;
		return decodedToken.exp > currentTime;
	},[])

	const login = useCallback(async (username: string, password: string) => {
		const token = await loginApi(username, password);
		const decoded = jwt_decode<any>(token);
		
		setToken(token);
		const user = await getUser(decoded.sub);

		dispatch({ type: 'LOGIN', payload: { user }});
	}, [])

	const logout = () => {
		setToken(null);
		dispatch({ type: 'LOGOUT' });
	}

	useEffect(() => {
		const initUser = async () => {
			try {
				const accessToken = window.localStorage.getItem(KEY_TOKEN_AUTH);

				if (accessToken && isValidToken(accessToken)) {
					setToken(accessToken);
					const decoded = jwt_decode<any>(accessToken);
					const user = await getUser(decoded.sub);

					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: true,
							user,
						},
					})
				} 
				else {
					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					})
				}
			} catch (err) {
				dispatch({
					type: 'INIT',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				})
			}
		};

		initUser();
	}, [])

    if(!state.isInitialised)
			return <Loading />

    return (
			<AuthContext.Provider
				value={{
					...state,
					method: 'JWT',
					login,
					logout
				}}
			>
				{children}
			</AuthContext.Provider>
    )
}
