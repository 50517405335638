import React, { memo } from 'react'
import * as S from './styles'
import { Card, Chip } from 'components'

interface ISimpleCardProps {
	children: React.ReactNode,
	title?: string,
	subtitle?: string,
	badge?: string,
	className?: string
}

const SimpleCard : React.FC<ISimpleCardProps> = ({ 
	children, 
	title, 
	subtitle, 
	badge,
	className
}) => {
	return (
		<S.Container>
			<Card className={`simple-card-container ${className}`} elevation={6}>
				<S.CardTitle subtitle={subtitle}>
					{title}
					{badge && <Chip sx={{ml: 1}} label={badge} />}
				</S.CardTitle>
				{subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
				{children}
			</Card>
		</S.Container>
	)
}

export default memo(SimpleCard);
