import { forEach, merge } from 'lodash';
import themeOptions from '../MatxTheme/themeOptions';
import { themeColors } from '../MatxTheme/themeColors';
import { createTheme } from '@mui/material';

const createMatxThemes = () => {
	let themes = {}

	forEach(themeColors, (theme, key) => {
		themes[key] = createTheme(merge({}, themeOptions, theme))
	})
	return themes;
}

export const themes = createMatxThemes();
