import React, { createContext, useCallback } from 'react'
import { SnackbarOrigin, useSnackbar } from 'notistack'
import { INotifyProps } from 'hooks/use-notify';

export const NotifyContext = createContext<INotifyProps | null>(null);

const notifyAnchor : SnackbarOrigin = {
	vertical: 'top',
	horizontal: 'center'
}

export const NotifyProvider: React.FC = ({ children }) => {
	console.log('renderizou NotifyProvider');
	const { enqueueSnackbar } = useSnackbar();

	const error = useCallback((message, error?) => {
		enqueueSnackbar(message, { 
			variant: 'error',
			anchorOrigin: notifyAnchor,
		})
		console.log(`
			❌ TO FAZENDO ERRO : ${message}

			[ORIGEM] 
			${error}
		`);
	}, [])

	const success =  useCallback((message) => {
		enqueueSnackbar(message, { 
			variant: 'success',
			anchorOrigin: notifyAnchor,
		})
	}, [])

	const warning =  useCallback((message) => {
		enqueueSnackbar(message, { 
			variant: 'warning',
			anchorOrigin: notifyAnchor,
		})
	}, [])

	return (
		<NotifyContext.Provider value={{ error, success, warning }} >
			{children}
		</NotifyContext.Provider>
	)
}

export default NotifyContext;

