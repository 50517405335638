import React, { InputHTMLAttributes, memo } from 'react';
import { AiOutlineSearch } from 'react-icons/ai'
import { FormControl, TextField } from 'components';
import { InputAdornment, IconButton, Stack } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

interface ITextFieldSearchProps extends InputHTMLAttributes<HTMLInputElement> {
	setTextSearch: (newValue: string) => void,
	textSearch: string,
	label: string
}

const TextFieldSearch: React.FC<ITextFieldSearchProps> = ({
	label,
	textSearch,
	setTextSearch,
}) =>{

	const handleClose = () => setTextSearch('');

	return (
		<FormControl sx={{ flex: 1 }}>
			<TextField
				id="outlined-adornment-search"
				name='button-search'
				variant='outlined'
				type='text'
				value={textSearch}
				size={'small'}
				onChange={event => setTextSearch(event.target.value)}
				label={label}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Stack direction="row" spacing={1}>
								{!!textSearch.length && 
									<IconButton 
										aria-label="Icone para fechar" 
										edge="end"
										onClick={handleClose}
									>
										<CloseOutlined sx={{ fontSize: "1rem" }} color="error"  />
									</IconButton>
								}
								<IconButton aria-label="Icone de lupa para pesquisar" edge="end" >
									<AiOutlineSearch />
								</IconButton>
							</Stack>
						</InputAdornment>
					)
				}}
			/>
		</FormControl>
  )
}

export default memo(TextFieldSearch);
