import styled from 'styled-components'

interface TabProps {
  text?: string
  amount?: number
  active?: boolean
}

export const Container = styled.section<TabProps>`
	.status-filter-item {
		min-width: 180px;
		& .MuiTabs-indicator {
			display: flex;
			justify-content: center;
			background-color: transparent;
		}
		& .MuiTabs-indicatorSpan {
			max-width: 40px;
			width: 100%;
		}
	}
`