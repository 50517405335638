import styled from 'styled-components'

type Props = {
	mode: string,
}

export const Container = styled.section<Props>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 18px 20px 29px;

	& .sidenavHoverShow {
		display: ${props => props.mode === 'compact' ? 'none' : 'block'}; 
	}
`

export const Header = styled.section`
	display: flex;
	align-items: center;
`

export const StyledSpan = styled.span<Props>`
	font-size: 18px;
	margin-left: .5rem;
	display: ${props => props.mode === 'compact' ? 'none' : 'block'};
	color: ${props => props.theme.palette.common.white}
`
