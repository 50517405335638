import styled from 'styled-components';

export const Container = styled.section``;

export const StyledText = styled.span<{mode: string}>`
	flex: 1;
	text-align: left;
	font-size: 0.875rem;
	padding-left: 0.8rem;
	display: ${props => props.mode === 'compact' && 'none' };
`;

export const ListLabel = styled.p<{mode: string}>`
	font-size: 12px;
	margin-top: 20px;
	margin-left: 15px;
	margin-bottom: 10px;
	text-transform: uppercase;
	display: ${props => props.mode === 'compact' && 'none' };
	color: rgba(255, 255,255,0.7);
`;

export const ExtAndIntCommon = styled.section`
	display: flex;
	overflow: hidden;
	border-radius: 4px;
	height: 44px;
	white-space: pre;
	margin-bottom: 8px;
	text-decoration: none;
	transition: all 150ms ease-in;
	align-items: stretch;
	&:hover {
		background: rgba(255, 255, 255, 0.08);
	}
	&.compactNavItem {
		overflow: hidden;
		justify-content: center !important;
	}
	& .icon-container {
		font-size: 18px;
		padding-left: 16px;
		padding-right: 16px;
		vertical-align: middle;
		width: 36px;
		flex: none;
	}
`;

export const InternalLink = styled(ExtAndIntCommon)`
	& .icon-container {
		padding: 0;
		justify-content: center;
	}

	& a {
		color: ${props => props.theme.palette.common.white};
		flex: 1;
		button { 
			width: 100%;
			height: 100%;
		}
	}
  & .navItemActive {
		background-color: rgba(255, 255, 255, 0.16);
	}
`;
