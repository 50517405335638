import axios from 'axios';

const api = axios.create({
	baseURL: 'https://to-fazendo-api.herokuapp.com/api/',
	// baseURL: 'http://192.168.1.134:3002/api/',
})

export const setHeaderBearer = (accessToken: string) => {
	if (accessToken)
	api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	else 
	delete api.defaults.headers.common.Authorization;
}

export default api;

export * from './auth';
export * from './tasks';
export * from './campaigns';
export * from './users';
export * from './companies';
