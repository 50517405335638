export interface ICompanyProps {
	id: string,
	codigo: string,
	nome: string,
	status: string,
	tipo: string,
	data_criacao: Date,
}

export enum StatusCompanyEnum {
	Todas = 'T',
	Ativa = 'A',
	Inativa = 'I',
	Suspensa = 'S',
	Cancelada = 'C',
}

export enum TypeCompanyEnum {
	Filial = 'F',
	Matriz = 'M'
}
