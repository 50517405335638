import React, { useCallback, useEffect, useState } from "react";
import { createContext } from 'use-context-selector';
import useNotify from "hooks/use-notify";
import { getTasks, removeTask } from 'services/api';
import { PageState, ITaskProps, StatusTaskEnum } from "interfaces";
import { initialTasksState } from "./initial-state-contexts";
import { useQuery } from "react-query"

export const TasksContext = createContext(initialTasksState);

export const StoreTasks: React.FC = ({children}) => {
	const [state, setState] = useState<PageState<ITaskProps>>(initialTasksState);
	const [textSearch, setTextSearch] = useState<string>('');
	const [tasks, setTasks] = useState<ITaskProps[]>([]);
	const [status, setStatus] = useState<string>(StatusTaskEnum.Todas);
	const [tasksFiltered, setTasksFiltered] = useState<ITaskProps[]>([]);
	const notify = useNotify();

	const { isLoading, isError } = useQuery<ITaskProps[], Error, ITaskProps[]>(
			'tasks', 
			async () => {
			const taskList = await getTasks();
			setTasks(taskList);
			return taskList;
		},
		{
			onSuccess: () => console.log('Consulta de tarefas realizada com sucesso'),
			onError: (error) => notify.error(`❌ Não foi possível buscar as tarefas ❌\n\n${error}`)
		}
	)

	const handleRemoveTask = useCallback(async (taskId: string) => {
		const response = await removeTask(taskId);
		if (response.status === 200) {
			setTasks(oldTasks => oldTasks.filter(task => task.id !== taskId));
		}
	}, [setTasks])

	const makeAmounts = useCallback((tasks: ITaskProps[]) => {
		if(!tasks) return;

		let amountAtive = tasks.filter(task => (task.status as StatusTaskEnum) === StatusTaskEnum.Ativa).length;
		let amountInactive = tasks.filter(task => (task.status as StatusTaskEnum) === StatusTaskEnum.Inativa).length;
		let amountCancelled = tasks.filter(task => (task.status as StatusTaskEnum) === StatusTaskEnum.Cancelada).length;
		
		return (
			{
				todas: tasks.length,
				ativas: amountAtive,
				inativas: amountInactive,
				canceladas: amountCancelled,
			}
		)
	}, [])

	useEffect(() => {
		let tasksFiltered = tasks;

		if(status !== StatusTaskEnum.Todas)
			tasksFiltered = tasksFiltered.filter(task => task.status === status);

		if(!!textSearch) {
			tasksFiltered = tasksFiltered.filter(task => 
				task.nome && task.nome.toLowerCase().includes(textSearch.toLowerCase())
			);
		}

		setTasksFiltered(tasksFiltered);
	}, [tasks, status, textSearch, setTasksFiltered])

	const handleSetTextSearch = useCallback((newTextSearch) => {
		setTextSearch(newTextSearch)
	},[])

	const handleSetStatus = useCallback((newStatus) => {
		setStatus(newStatus)
	},[])

	const handleSetTypeShow = useCallback((newTypeShow) => {
		setState(oldValues => ({...oldValues, typeShow: newTypeShow}))
	},[])

	return (
		<TasksContext.Provider value={{
			search: textSearch,
			setSearch: (newTextSearch) => handleSetTextSearch(newTextSearch),
			status,
			setStatus: (newStatus) => handleSetStatus(newStatus),
			typeShow: state.typeShow,
			setTypeShow: (newType) => handleSetTypeShow(newType),
			list: tasksFiltered,
			remove: (id: string) => handleRemoveTask(id),
			setList: setTasks,
			isLoading,
			isError,
			amounts: makeAmounts(tasks)
		}}>
			{children}
		</TasksContext.Provider>
	)
}