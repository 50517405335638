import * as Yup from 'yup';

export interface IAddressProps {
	cep: string,
	logradouro: string,
	bairro: string,
	numero: string,
	localidade: string,
	uf: string,
	complemento: string,
}

export const initAddressProps = {
	cep: '',
	logradouro: '',
	numero: '',
	bairro: '',
	localidade: '',
	uf: '',
	complemento: ''
}

export const getAddressValidation = Yup.object({
	cep: Yup
		.string()
		.max(255)
		.required(
			'O cep do endereço é obrigatório'),
	numero: Yup
		.string()
		.max(255)
		.required(
			'O número do endeereço é obrigatório'),
})
