import { useContext } from 'react'
import NotifyContext from 'contexts/notify-context'

export interface INotifyProps {
	error: (message: any, error?: Error) => void;
	success: (message: any) => void;
	warning: (message: any) => void;
}

const useNotify = () => useContext<INotifyProps>(NotifyContext)

export default useNotify
