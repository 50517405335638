import styled from 'styled-components';

export const MenuContainer = styled.section`
	display: flex;
	align-items: center;
`;

export const UserMenuContainer = styled.section`
	display: inline-block;
	& div:hover {
		/* background-color: ${props => props.theme.palette.action.hover}; */
	};
`;

export const UserMenuButton = styled.section`
	display: flex;
	align-items: center;
	color: rgba(52, 49, 76, 1);

	@media (max-width: 420px){
		color: ${props => props.theme.palette.common.white};
	}
	cursor: pointer;
	border-radius: 24px;
	padding: 4;
	& span {
		margin: 0 8px;
	}
`;
