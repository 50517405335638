import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export const SubName = styled.span`
	color: ${props => props.theme.palette.text.secondary};
	text-transform: capitalize;
`;

export const StyledIcon = styled.section`

	& > .material-icons {
		color: ${props => props.theme.palette.primary.main};
		margin-left: 8px;
		margin-bottom: 4px;
		vertical-align: middle;
	}
`;
