import styled from 'styled-components';

interface BadgeProps {
	bgColor?: string
	color?: string
}

export const Container = styled.span<BadgeProps>`
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  height: 16px;
  text-align: center;
  line-height: 16px;
  background-color: ${props => props.bgColor ?? props.theme.palette.primary.main};
  color: ${props => props.color ?? props.theme.palette.common.white};
  letter-spacing: 0.6px;
  padding: 0 4px;
  border-radius: 2px;
  text-transform: uppercase;
  cursor: pointer;
`;
