import React from 'react';
import { Loadable } from 'components';
import { StoreUsers } from 'contexts';
import { RouteObject } from 'react-router-dom';

const Users = Loadable(React.lazy(() => import('./index')));
const UserCreate = Loadable(React.lazy(() => import('pages/users/components/user-create')));

const usersRoutes : RouteObject[] = [
	{
		path: '/usuarios',
		element: 
			<StoreUsers>
				<Users />
			</StoreUsers>,
	},
	{
		path: '/usuario/:id',
		element: <UserCreate />
	},
	{
		path: '/usuario/criar',
		element: <UserCreate />,
	},
]

export default usersRoutes;
