import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
	@media screen and (max-width: 600px){
		.hide-on-mobile {
			display: none!important;
		}
	}

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  html {
    scroll-behavior: smooth;
		width: 100vw;
		height: 100vh;
  }
  body {
    background: #f4f6f8;
    -webkit-font-smoothing: antialiased;
		height: 100%;

		.scroll-styling {
			&::-webkit-scrollbar {
				background-color: #C4CDD5;
				border-radius: 8px;
				width: 10px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 8px;
				background-color: #7979F2;
				min-height: 24px;
				border: 3px solid #7979F2;
			}
			&::-webkit-scrollbar-thumb:focus {
				background-color: #9090f4;
			}
			&::-webkit-scrollbar-thumb:active {
				background-color: #9090f4;
			}
			&::-webkit-scrollbar-thumb:hover {
				background-color: #9090f4;
			}
			&::-webkit-scrollbar-corner {
				background-color: #9090f4;
			}
		}
  }
  ul { list-style: none; }
  input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
  }
  a { text-decoration: none; }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    transition: background-color .3s;

    & > svg { cursor: pointer; }
  }

  #root {
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    margin: 0 auto;
  }

  .mt-24 { margin-top: 24px; }
  .ml-24 { margin-left: 24px; }
  .mr-24 { margin-right: 24px; }
  .mb-24 { margin-bottom: 24px; }

  .mt-18 { margin-top: 18px; }
  .ml-18 { margin-left: 18px; }
  .mr-18 { margin-right: 18px; }
  .mb-18 { margin-bottom: 18px; }

  .mt-12 { margin-top: 12px; }
  .ml-12 { margin-left: 12px; }
  .mr-12 { margin-right: 12px; }
  .mb-12 { margin-bottom: 12px; }

  .mt-8 { margin-top: 8px; }
  .ml-8 { margin-left: 8px; }
  .mr-8 { margin-right: 8px; }
  .mb-8 { margin-bottom: 8px; }

  .mt-4 { margin-top: 4px; }
  .ml-4 { margin-left: 4px; }
  .mr-4 { margin-right: 4px; }
  .mb-4 { margin-bottom: 4px; }

  .light { background-color: #ffffff }
  .dark { background-color: rgba(0,0,0,0.38) }
	.tst { border: solid 1px red; }

	.pt-24 { padding-top: 24px; }
  .pl-24 { padding-left: 24px; }
  .pr-24 { padding-right: 24px; }
  .pb-24 { padding-bottom: 24px; }

  .pt-18 { padding-top: 18px; }
  .pl-18 { padding-left: 18px; }
  .pr-18 { padding-right: 18px; }
  .pb-18 { padding-bottom: 18px; }

  .pt-12 { padding-top: 12px; }
  .pl-12 { padding-left: 12px; }
  .pr-12 { padding-right: 12px; }
  .pb-12 { padding-bottom: 12px; }

  .pt-8 { padding-top: 8px; }
  .pl-8 { padding-left: 8px; }
  .pr-8 { padding-right: 8px; }
  .pb-8 { padding-bottom: 8px; }

  .pt-4 { padding-top: 4px; }
  .pl-4 { padding-left: 4px; }
  .pr-4 { padding-right: 4px; }
  .pb-4 { padding-bottom: 4px; }
`	
