import { useCallback, useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import { getUsers } from "services/api";
import useNotify from "hooks/use-notify";
import { PageState, IUserProps, StatusUserEnum } from "interfaces";
import { initialUsersState } from "./initial-state-contexts";
import { useQuery } from "react-query";

export const UsersContext = createContext(initialUsersState);

export const StoreUsers: React.FC = ({children}) => {
	console.log('renderizou UsersContext');
	const [state, setState] = useState<PageState<IUserProps>>(initialUsersState);
	const [textSearch, setTextSearch] = useState<string>('');
	const [users, setUsers] = useState<IUserProps[]>([]);
	const [status, setStatus] = useState<string>(StatusUserEnum.Todos);
	const [usersFiltered, setUsersFilted] = useState<IUserProps[]>([]);
	const notify = useNotify();

	const { isLoading, isError } = useQuery<IUserProps[], Error, IUserProps[]>(
		'users', 
		async () => {
			const usersList = await getUsers();
			setUsers(usersList);
			return usersList;
		},
		{
			onSuccess: () => console.log('Consulta de usuários realizada com sucesso'),
			onError: (error) => notify.error(`❌ Não foi possível buscar os usuários ❌\n\n${error}`)
		}
	)

	const makeAmounts = (users: IUserProps[]) => {
		let amountAtive = users?.filter(task => (task.status as StatusUserEnum) === StatusUserEnum.Ativo).length;
		let amountInactive = users?.filter(task => (task.status as StatusUserEnum) === StatusUserEnum.Inativo).length;
		let amountSuspended = users?.filter(task => (task.status as StatusUserEnum) === StatusUserEnum.Suspenso).length;
		
		return (
			{
				todos: users?.length,
				ativos: amountAtive,
				inativos: amountInactive,
				suspensos: amountSuspended,
			}
		)
	}

	useEffect(() => {
		var usersFiltered = users

		if(status !== StatusUserEnum.Todos)
			usersFiltered = usersFiltered.filter(user => user.status === status)

		if(!!textSearch) {
			usersFiltered = usersFiltered.filter(user => (
				(user.nome && user.nome.toLowerCase().includes(textSearch.toLowerCase()))
				|| (user.login && user.login.toLowerCase().includes(textSearch.toLowerCase()))
			))
		}

		setUsersFilted(usersFiltered);
	}, [users, status, textSearch])

	const handleSetStatus = useCallback((newStatus) => {
		setStatus(newStatus)
	}, [])

	const handleSetTextSearch = useCallback((newTextSearch) => {
		setTextSearch(newTextSearch)
	}, [])

	const handleSetTypeShow = useCallback((newTypeShow) => {
		setState(oldValues => ({...oldValues, typeShow: newTypeShow}))
	}, [])

	return (
		<UsersContext.Provider value={{
			search: textSearch,
			setSearch: (newValue) => handleSetTextSearch(newValue),
			status,
			setStatus: (newStatus) => handleSetStatus(newStatus),
			typeShow: state.typeShow,
			setTypeShow: (newType) => handleSetTypeShow(newType),
			list: usersFiltered,
			setList: setUsers,
			isLoading,
			isError,
			amounts: makeAmounts(users)
		}}>
			{children}
		</UsersContext.Provider>
	)
}
 