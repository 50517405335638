export interface IUserProps {
	id: string,
  nome: string,
  login: string,
  celular: string,
  status: string,
  perfil: string,
  empresa_id: number,
	data_criacao: Date,
  img?: string,
	pontos: number,
	totais: {
		totalTarefasConcluidas: number,
		totalParticipacoesCampanhas: number,
		totalConquistas: number
	}
}

export enum StatusUserEnum {
	Todos = 'T',
	Ativo = 'A',
	Inativo = 'I',
	Suspenso = 'S',
}

export enum PerfilUserEnum {
	Usuario = 'C',
	Moderador = 'M',
	Administrador = 'A'
}