import React, { Suspense } from 'react';
import { Loading } from 'components';

const Loadable = (Component) => (props) => (
	<Suspense fallback={<Loading />}>
		<Component {...props} />
	</Suspense>
)

export default Loadable;
