import React from 'react';
import { ForwardRefComponent, HTMLMotionProps, motion } from 'framer-motion';
import { IVariantsProps } from 'interfaces';

interface IAnimatedListItemProps extends Omit<ForwardRefComponent<HTMLLIElement, HTMLMotionProps<"li">>, '$$typeof'> {
	key: string
}

export const cardAnimationDefault : IVariantsProps = {
	hidden: { y: 60, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
		transition:{
			duration: .3
		}
	},
	exit: { 
		opacity: 0, 
		scale: 0,
		transition: { duration: .2 }
	}
};
 
const AnimatedListItem : React.FC<IAnimatedListItemProps> = ({
	children,
	key
}) => {
	return ( 
		<motion.li 
			className='animated-list-item'
			key={key}
			layoutId={key}
			variants={cardAnimationDefault}
			exit={cardAnimationDefault.exit}
		>
			{children}
		</motion.li>
	 );
}
 
export default AnimatedListItem;
