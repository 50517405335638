import React from 'react'
import { MatxLayouts } from './index'
import { Suspense } from 'components'
import useSettings from 'hooks/use-settings'

const MatxLayout = (props) => {
	const { settings } = useSettings()
	const Layout = MatxLayouts[settings.activeLayout]

	return (
		<Suspense>
			<Layout {...props} />
		</Suspense>
	)
}

export default MatxLayout
