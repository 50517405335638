export interface ITaskProps {
	id: string,
  nome: string,
  descricao: string,
  tipo: string,
  pontos: number,
  status: string,
  statusCampanhas: string,
	data_criacao: Date
}

export enum StatusTaskEnum {
	Todas = 'T',
	Ativa = 'A',
	Inativa = 'I',
	Cancelada = 'C',
}

export enum TypeTaskEnum {
	Digitada = 'D',
	Presenca = 'P',
	Percentual = 'T'
}