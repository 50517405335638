import styled from 'styled-components';

export const Container = styled.section``;

export const Header = styled.section`
	padding-left: 1rem;
	padding-right: 1rem;
	position: relative;
`;

export const ContainerMobile = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	background: rgba(0, 0, 0, 0.54);
	z-index: -1;
`;
