import Animated from 'components/animated'
import React, { memo, useCallback } from 'react';
import * as S from './styles';
import { zoomInAnimation } from 'styles/animation';
import { imgEmpty, imgError, imgLoading } from './images64';

export type StatusProps = 'loading' | 'error' | 'empty' | 'filled';

interface ListStatusProps {
	message: string,
	status: StatusProps
}

const ListStatus: React.FC<ListStatusProps> = ({
	message,
	status
} : ListStatusProps) =>{
	const getImage = useCallback(() => {

		switch (status) {
			case 'loading':
				return imgLoading;
			case 'error':
				return imgError;
			case 'empty':
				return imgEmpty;
			default:
				return imgLoading;
		}
	},[])

 	return (
		<Animated variants={zoomInAnimation(0.3)}>
			<S.Container className='list-status'>
				<section className='body'>
					<img src={getImage()} alt="Icone que representa o status da lista de tarefas" />
					<p>{message}</p>
				</section>
			</S.Container>
		</Animated>
  )
}

export default memo(ListStatus);
