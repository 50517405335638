import { red } from '@mui/material/colors'
import { components } from './components-mui'

const themeOptions = {
	typography: {
		fontSize: 14,
		body1: {
			fontSize: '14px',
		},
	},
	breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
	status: {
		danger: red[500],
	},
	components: {
		...components,
	},
}

export default themeOptions
