import { themeShadows } from "./shadows";

export type ThemeColorsNames = 
	'gamefication' | 'slateDark1' | 'whitePurple'
	| 'whiteBlue' | 'slateDark2' | 'purple1'
	| 'purple2' | 'purpleDark1' | 'purpleDark2'
	| 'blue' | 'blueDark' | 'red';

const textLight = {
	primary: 'rgba(52, 49, 76, 1)',
	secondary: 'rgba(52, 49, 76, 0.54)',
	disabled: 'rgba(52, 49, 76, 0.38)',
	hint: 'rgba(52, 49, 76, 0.38)',
}
const textDark = {
	primary: '#fff',
	secondary: 'rgba(255, 255, 255, 0.7)',
	disabled: 'rgba(255, 255, 255, 0.64)',
	hint: 'rgba(255, 255, 255, 0.64)',
}
const secondaryColor = {
	main: '#FD8E39',
	light: '#feba86',
	dark: '#de6202',
	contrastText: textLight.primary,
}
const errorColor = {
  main: '#F64B3C',
	light: '#F87063',
	dark: '#9C1407',
	contrastText: '#212B36',
}

export const themeColors = {
	
	gamefication: {
		shadows: themeShadows,
		palette: {
			type: 'light',
			common: {
				white: '#fff',
				black: 'rgba(0,0,0,0.48)',
				gray: '#34314c8a',
			},
			primary: {
				main: '#7979F2',
				dark: '#5858ee',
				light: '#9090f4',
				contrastText: 'rgba(255,255,255,0.87)',
			},
			secondary: {
				main: '#FD8E39',
				light: '#feba86',
				dark: '#de6202',
				contrastText: 'rgba(255,255,255,0.87)',
			},
			info: {
				main: '#1890FF',
				dark: '#0c53b7',
				light: '#74caff',
				contrastText: 'rgba(255,255,255,0.87)',
			},
			success: {
				main: '#2EFF9E',
				light: '#99ffcf',
				dark: '#08ad6c',
				contrastText: '#212B36',
			},
			grey: {
				50: '#FFFFFF',
				100: '#F9FAFB',
				200: '#F4F6F8',
				300: '#DFE3E8',
				400: '#C4CDD5',
				500: '#919EAB',
				600: '#637381',
				700: '#454F5B',
				800: '#212B36',
				900: '#161C24',
			},
			error: errorColor,
			background: {
				paper: '#fff',
				default: '#f4f6f8',
			},
			text: textLight,
		},
	},
	whitePurple: {
		palette: {
			type: 'light',
			primary: {
				main: '#ffffff',
				contrastText: textLight.primary,
			},
			secondary: {
				main: '#7467ef',
				contrastText: '#ffffff',
			},
			background: {
				paper: '#fff',
				default: '#fafafa',
			},
			error: errorColor,
			text: textLight,
		},
	},
	whiteBlue: {
		palette: {
			type: 'light',
			primary: {
				main: '#ffffff',
				contrastText: textLight.primary,
			},
			secondary: {
				main: '#1976d2',
				contrastText: '#ffffff',
			},
			background: {
				paper: '#fff',
				default: '#fafafa',
			},
			text: textLight,
		},
	},
	slateDark1: {
		palette: {
			type: 'dark',
			primary: {
				main: '#222A45',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ff9e43',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#222A45',
				default: '#1a2038',
			},
			text: textDark,
		},
	},
	slateDark2: {
		palette: {
			type: 'dark',
			primary: {
				main: '#1a2038',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ff9e43',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#222A45',
				default: '#1a2038',
			},
			text: textDark,
		},
	},
	purple1: {
		palette: {
			type: 'light',
			primary: {
				main: '#7467ef',
				contrastText: '#ffffff',
			},
			secondary: secondaryColor,
			error: errorColor,
			background: {
				paper: '#fff',
				default: '#fafafa',
			},
			text: textLight,
		},
	},
	purple2: {
		palette: {
			type: 'light',
			primary: {
				main: '#6a75c9',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ff9e43',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#fff',
				default: '#fafafa',
			},
			text: textLight,
		},
	},
	purpleDark1: {
		palette: {
			type: 'dark',
			primary: {
				main: '#7467ef',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ff9e43',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#222A45',
				default: '#1a2038',
			},
			text: textDark,
		},
	},
	purpleDark2: {
		palette: {
			type: 'dark',
			primary: {
				main: '#6a75c9',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#ff9e43',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#222A45',
				default: '#1a2038',
			},
			text: textDark,
		},
	},
	blue: {
		palette: {
			type: 'light',
			primary: {
				main: '#1976d2',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#FFAF38',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#fff',
				default: '#f4f6f8',
			},
			text: textLight,
		},
	},
	blueDark: {
		palette: {
			type: 'dark',
			primary: {
				main: '#1976d2',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#FF4F30',
				contrastText: textLight.primary,
			},
			error: errorColor,
			background: {
				paper: '#222A45',
				default: '#1a2038',
			},
			text: textDark,
		},
	},
	red: {
		palette: {
			type: 'dark',
			primary: {
				main: '#e53935',
				contrastText: '#ffffff',
			},
			secondary: {
				main: '#FFAF38',
				contrastText: textLight.primary,
			},
			error: errorColor,
			text: textDark,
		},
	},
}
