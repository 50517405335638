import React, { memo } from 'react'
import { Chip as ChipMUI, useMediaQuery, ChipProps as ChipPropsMUI} from '@mui/material'
import { useTheme } from '@mui/material'

interface ChipProps extends ChipPropsMUI {
	small?: boolean
}
 
const Chip: React.FC<ChipProps> = ({ small, ...props }) => {
	const { breakpoints } = useTheme();
	const isMobile = useMediaQuery(breakpoints.down(500));

	return (
		<ChipMUI
			{...props}
			{...(small && { size: 'small' })}
		/>
	);
}
 
export default memo(Chip);
