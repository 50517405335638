import React, { useCallback, useEffect, useState } from "react";
import useNotify from "hooks/use-notify";
import { getCompanies } from "services/api"
import { useQuery } from "react-query"
import { PageState } from "interfaces/interfaces";
import { ICompanyProps, StatusCompanyEnum } from "interfaces/companyes";
import { initialCompanyesState } from "./initial-state-contexts";
import { createContext } from "use-context-selector";

export const CompanyesContext = createContext(initialCompanyesState);

export const StoreCompanyes: React.FC = ({children}) => {
	console.log('renderizou CompanyesContext');
	const [state, setState] = useState<PageState<ICompanyProps>>(initialCompanyesState);
	const [textSearch, setTextSearch] = useState<string>('');
	const [companyes, setCompanyes] = useState<ICompanyProps[]>([]);
	const [status, setStatus] = useState<string>(StatusCompanyEnum.Todas);
	const [companyesFiltered, setCompanyesFiltered] = useState<ICompanyProps[]>([]);
	const notify = useNotify();

	const { isLoading, isError } = useQuery<ICompanyProps[], Error, ICompanyProps[]>(
		'companyes',
		async () => {
			const companiesList = await getCompanies();
			setCompanyes(companiesList);
			return companiesList;
		},
		{
			onSuccess: () => console.log('Consulta de empresas realizada com sucesso'),
			onError: (error) => notify.error(`❌ Não foi possível buscar as empresas ❌\n\n${error}`)
		}
	)

	useEffect(() => {
		let companyesFiltered = companyes;

		if(status !== StatusCompanyEnum.Todas)
			companyesFiltered = companyesFiltered.filter(company => company.status === status);

		if(textSearch) {
			companyesFiltered = companyesFiltered.filter(company => 
				company.nome && company.nome.toLowerCase().includes(textSearch.toLowerCase())
			)
		}

		setCompanyesFiltered(companyesFiltered)
	}, [companyes, status, textSearch, setCompanyesFiltered])

	const handleSetTextSearch = useCallback((newTextSearch) => {
		setTextSearch(newTextSearch)
	},[setTextSearch])

	const handleSetStatus = useCallback((newStatus) => {
		setStatus(newStatus)
	},[setStatus])

	const handleSetTypeShow = useCallback((newTypeShow) => {
		setState(oldValues => ({...oldValues, typeShow: newTypeShow}))
	},[])

	return (
		<CompanyesContext.Provider value={{
			search: textSearch,
			setSearch: (newTextSearch) => handleSetTextSearch(newTextSearch),
			status,
			setStatus: (newStatus) => handleSetStatus(newStatus),
			typeShow: state.typeShow,
			setTypeShow: (newType) => handleSetTypeShow(newType),
			list: companyesFiltered,
			remove: null,
			setList: setCompanyes,
			isLoading,
			isError,
			amounts: null,
		}}>
			{children}
		</CompanyesContext.Provider>
	)
}
