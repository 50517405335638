import React from 'react';
import { BaseTextFieldProps, TextField as TextFieldMUI } from '@mui/material';

export interface IInputProps extends BaseTextFieldProps {
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
	InputProps?: any,
}

const TextField: React.FC<IInputProps> = ({variant, ...props}) =>{
  return <TextFieldMUI variant={variant ?? 'filled'} {...props} />
}

export default React.memo(TextField);
