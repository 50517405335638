import React, { useCallback, useState } from 'react';
import { merge } from 'lodash';
import { LayoutSettings } from 'config/app-settings';
import { createContext } from 'use-context-selector';

const initialValues = {
	settings: LayoutSettings,
  leftSidenavMode: LayoutSettings.layoutSettings.leftSidebar.mode,
  updateSettings: (update = {}) => {},
	updateSidebarMode: (mode: 'full' | 'close' | 'compact' | 'mobile') => {},
}

const SettingsContext = createContext(initialValues);

export const SettingsProvider : React.FC = ({ children }) => {
	console.log('renderizou SettingsProvider');
	const [currentSettings, setCurrentSettings] = useState(initialValues.settings);

	const handleUpdateSettings = useCallback((update = {}) => {
    const marged = merge({}, currentSettings, update);
    setCurrentSettings(marged)
	},[])

	const handleUpdateSidebarMode = useCallback((mode) => {
		console.table({layoutSettings: {
			leftSidebar: {
				...{mode},
			},
		}})
		handleUpdateSettings({
			layoutSettings: {
				leftSidebar: {
					...{mode},
				},
			},
		});
	},[])

	return (
		<SettingsContext.Provider
			value={{
				settings: currentSettings,
				updateSettings: handleUpdateSettings,
				updateSidebarMode: handleUpdateSidebarMode,
				leftSidenavMode: currentSettings.layoutSettings.leftSidebar.mode
			}}
		>
			{children}
		</SettingsContext.Provider>
	)
}

export default SettingsContext;
