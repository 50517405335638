import api from 'services/api';
import { ICompanyProps } from 'interfaces';

const PATH = 'empresa';

export const getCompanies = async () => {
	try {
		let companiesList = await api.get<ICompanyProps[]>(`${PATH}s`);

		return companiesList?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
}
