import React, { memo } from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { StyledLoading } from './styles';

const LoadingMUI : React.FC = () => {
	return (
		<StyledLoading>
			<Box position="relative">
				<CircularProgress className="circleProgress" />
			</Box>
		</StyledLoading>
	)
}

export default memo(LoadingMUI);
