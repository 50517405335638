import styled from 'styled-components'

type Props = {
	subtitle: string
}

export const Container = styled.section`
	.simple-card-container {
		height: 100%;
		padding: 20px 24px;
	}
`
export const CardTitle = styled.section<Props>`
	font-size: 1.5rem;
	font-weight: 500;
	text-transform: capitalize;
	margin-bottom: 16px;
`

export const Subtitle = styled.p`
	margin-bottom: 2rem;
`
