import React, { memo, useState } from 'react'
import { Container } from './styles'
import { Accordion as AccordionMUI } from '@mui/material'

const Accordion = ({children}) => {
	const [isOpened, setOpened] = useState(false)
	const handleChangeExpand = () => setOpened(!isOpened)
	
	return (
	<Container className='container-accordion'>
		<AccordionMUI expanded={isOpened} onChange={handleChangeExpand} >
			{children}
		</AccordionMUI>
	</Container>
	)
}

export default memo(Accordion)
