import React from 'react';
import useSettings from 'hooks/use-settings';
import * as S from './styles';
import { Brand } from 'components';

const LayoutBrand = ({ children }) => {
	const { settings } = useSettings()
	const leftSidebar = settings.layoutSettings.leftSidebar
	const { mode } = leftSidebar

	return (
		<S.Container mode={mode} className='brand-container'>
			<S.Header className='brand-header'>
				<Brand width={24} />
				<S.StyledSpan mode={mode} className="sidenavHoverShow">
					TO FAZENDO
				</S.StyledSpan>
			</S.Header>
			<section className="sidenavHoverShow">
				{children || null}
			</section>
		</S.Container>
	)
}

export default React.memo(LayoutBrand);
