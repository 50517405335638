import React from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './style';
import useSettings from 'hooks/use-settings';
import { Icon, ButtonBase } from 'components';

const VerticalNav = ({ items }) => {
	const isMobile = window.matchMedia(`(max-width: 420px)`).matches;
	const { leftSidebarMode, updateSidebarMode } = useSettings();

	const renderLevels = (data) => {
		return data.map((item, index) => {
			if (item.type === 'label'){
				return (
					<S.ListLabel
						key={index}
						mode={leftSidebarMode}
						className="sidenavHoverShow label-divider"
					>
						{item.label}
					</S.ListLabel>
				)
			}
			return (
				<S.InternalLink key={index}>
					<NavLink
						to={item.path}
						className={({ isActive }) =>
							isActive 
								? `navItemActive ${leftSidebarMode === 'compact' && 'compactNavItem'}` 
								: `${leftSidebarMode === 'compact' && 'compactNavItem'}`
						}
						onClick={() => updateSidebarMode('compact')}
					>
						<ButtonBase
							onClick={() => isMobile && updateSidebarMode('close')}
						>
							<Icon className="icon" sx={{maxWidth: 45}}>
								{item.icon}
							</Icon>
							<S.StyledText mode={leftSidebarMode} className="sidenavHoverShow" >
								{item.name}
							</S.StyledText>
						</ButtonBase>
					</NavLink>
				</S.InternalLink>
			)
		})
  }

	return (
		<S.Container className="vertical-nav-container">
			{renderLevels(items)}
		</S.Container>
	)
}

export default React.memo(VerticalNav);
