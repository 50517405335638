import React, { useCallback, useEffect, useState } from 'react';
import { getCampaigns } from 'services/api';
import useNotify from 'hooks/use-notify';
import { useQuery } from 'react-query';
import { ICampaignProps, PageState, StatusCampaignEnum } from 'interfaces';
import { initialCampaingsState } from './initial-state-contexts';
import { createContext } from 'use-context-selector';

export const CampaignsContext = createContext(initialCampaingsState)

export const StoreCampaings: React.FC = ({children}) => {
	const [state, setState] = useState<PageState<ICampaignProps>>(initialCampaingsState);
	const [textSearch, setTextSearch] = useState<string>('');
	const [campaigns , setCampaigns]  = useState<ICampaignProps[]>([]);
	const [status , setStatus]  = useState<string>(StatusCampaignEnum.Todas);
	const [campaignsFiltered , setCampaignsFiltered]  = useState<ICampaignProps[]>([]);
	const notify = useNotify();

	const { isLoading, isError } = useQuery<ICampaignProps[], Error, ICampaignProps[]>(
		'campaigns', 
		async () => {
			const tasksList = await getCampaigns(); 
			setCampaigns(tasksList);
			return tasksList;
		},
		{
			onSuccess: () => console.log('Consulta de campanhas realizada com sucesso'),
			onError: (error) => notify.error(`❌ Não foi possível buscar as campanhas ❌\n\n${error}`)
		}
	)
	
	useEffect(() => {
		var campaignsFiltered = campaigns;

		if(status !== StatusCampaignEnum.Todas)
			campaignsFiltered = campaignsFiltered.filter(campaign => campaign.status === state.status);

		if(textSearch) {
			campaignsFiltered = campaignsFiltered.filter(campaign => (
				(campaign.title && campaign.title.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase()))
				|| (campaign.description && campaign.description.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase()))
			))
		}

			setCampaignsFiltered(campaignsFiltered)
	}, [campaigns, status, textSearch, setCampaignsFiltered])

	const handleSetTextSearch = useCallback((newTextSearch) => {
		setTextSearch(newTextSearch)
	},[])

	const handleSetStatus = useCallback((newStatus) => {
		setStatus(newStatus)
	},[])

	const handleSetTypeShow = useCallback((newTypeShow) => {
		setState(oldValues => ({...oldValues, typeShow: newTypeShow}))
	},[])

	return (
		<CampaignsContext.Provider value={{
			search: textSearch,
			setSearch: (newValue) => handleSetTextSearch(newValue),
			status,
			setStatus: (newStatus) => handleSetStatus(newStatus),
			typeShow: state.typeShow,
			setTypeShow: (newType) => handleSetTypeShow(newType),
			list: campaignsFiltered,
			remove: null,
			setList: setCampaigns,
			isLoading,
			isError,
			amounts: null
		}}>
			{children}	
		</CampaignsContext.Provider>
	)
}
 